import { Title } from '@solidjs/meta';
import { useNavigate, useSearchParams } from '@solidjs/router';
import { clientAction, ttlCache } from '@troon/api-client';
import {
	Button,
	Errors,
	FieldDescription,
	Form,
	Heading,
	Input,
	Label,
	MessageBar,
	TextField,
	TextLink,
} from '@troon/ui';
import { createEffect, createMemo, onMount, Show } from 'solid-js';
import { IconLogo } from '@troon/icons/logo';
import { IconCircleCheck } from '@troon/icons/circle-check';
import { useUser } from '../../../providers/root';
import type { RouteSectionProps } from '@solidjs/router';

export default function Login(props: RouteSectionProps) {
	const navigate = useNavigate();
	const [params] = useSearchParams<{ redirect?: string }>();
	const user = useUser();

	const redirectPath = createMemo(() => {
		return !params.redirect || params.redirect?.startsWith('/auth') ? '/' : params.redirect;
	});

	onMount(() => {
		ttlCache?.delete('/v0/operator/auth/me');
	});

	createEffect(() => {
		if (user()) {
			navigate(redirectPath(), { replace: true });
		}
	});

	return (
		<>
			<Title>Log in | Troon Operator</Title>
			<IconLogo title="Troon" class="w-32" />
			<div class="flex flex-col items-center gap-2">
				<Heading as="h1" size="h2" class="text-center">
					Troon Operator
				</Heading>
				<p class="text-center">Log in to access your facility dashboard.</p>
			</div>

			<Show when={props.location.query.reset}>
				<MessageBar appearance="brand" icon={IconCircleCheck}>
					Your password was successfully reset. Please log in with your new password to continue.
				</MessageBar>
			</Show>

			<Form action={loginAction} method="post" class="w-full">
				<TextField name="email">
					<Label>Email address</Label>
					<Input inputMode="email" autocomplete="email" />
				</TextField>

				<TextField name="password">
					<Label>Password</Label>
					<Input type="password" />
					<FieldDescription>
						Forgot your password? <TextLink href={'/auth/forgot-password'}>Reset it now</TextLink>.
					</FieldDescription>
				</TextField>

				<Errors />

				<Button type="submit">Log in</Button>
				<p class="text-center text-sm text-neutral-700">
					Don't have an account? Please contact your facility's general manager or golf shop pro to get an invite.
				</p>
			</Form>
		</>
	);
}

const loginAction = clientAction('POST', '/v0/operator/auth/login', {}, { revalidate: ['/v0/operator/auth/me'] });
